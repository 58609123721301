@import "design/variables";
@import "design/template/mixin_templates";

/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* =============================================================================
   HTML5 element display
   ========================================================================== */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
}

audio[controls], canvas, video {
}

/* =============================================================================
   Base

html { -webkit-tap-highlight-color: rgba(0,0,0,0);}


/* =============================================================================
   Links
   ========================================================================== */

a {
    color: #00e;
}

a:visited {
    color: #551a8b;
}

a:focus {
}

/* Improve readability when focused and hovered in all browsers: people.opera.com/patrickl/experiments/keyboard/test */
a:hover, a:active {
}

.wrapper, .side-content ,
.apercu-article h2.titre-article, .apercu-article .h2.titre-article,
.single-article .titre-article h1, .single-article .titre-article .h1{
    background: #222222;
}

.sidebar ul > .actif > a {
    color: #FF4747;
}

hr {
    border-top: 1px solid #ccc;
}

ins {
    background: #ff9;
    color: #000;
}

mark {
    background: #ff0;
    color: #000;
}

.produits .produit_etiquette:after, .produit_etiquette::after {
    border-color: rgb(255, 71, 71) rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);

}

.produits .produit_etiquette, .produit_etiquette {
    background: rgb(255, 71, 71);
}

.produits .produit_etiquette2, .produit_etiquette2 {
    background: rgb(255, 71, 71);
}

.produits .produit_etiquette2:after, .produit_etiquette2::after {
    border-color: rgba(0, 0, 0, 0) rgb(255, 71, 71) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

/* =============================================================================
   a mettre dans tous les designs
   ========================================================================== */

#nav-static-principal > ul > li {
    border-bottom: 1px solid rgb(255, 255, 255);
}

/* =============================================================================*/

button, input[type="button"], input[type="reset"], input[type="submit"] {
}

/*
 * Consistent box sizing and appearance
 */

input[type="checkbox"], input[type="radio"] {
}

input[type="search"] {
}

button::-moz-focus-inner, input::-moz-focus-inner {
}

/* Remove default vertical scrollbar in IE6/7/8/9 */
textarea {
}

/* Colors for form validity */
input:valid, textarea:valid {
}

body, .modal-content {
    background-color: #222222;
    color: #fff;
}

header {
    background-color: #222222;
}

.wrapper .menu {
    background-image: none !important;
}

.slogan {
    color: #fff;

}

/*-----------------------------
CONTENU
-------------------------------*/
/*
 * Bloc de contenu de la page
 */

.boutique .produits .remise, #fiche-produit .remise-produit {
    background-color: #fff;
}

#fiche-produit .triangle-ficheprod .remise-produit {
    color: #000;
    white-space: nowrap;
}

/*#fiche-produit .wrap-description h3 {
  color: white;
}*/
#savoirplus h4,
#savoirplus .h4 {
    color: #fff;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
    background-color: #000;
    border-color: #000;
}

.pagination > li > a, .pagination > li > span {
    color: #000;
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

.links_p a {
    color: #fff;
}

/*-----------------------------
ENTETE DE PAGE 
-------------------------------*/

.quantite {
    color: #222;
}

/*-----------------------------
NAVIGATION 
-------------------------------*/

header {
    background: #222;
}

.slogan {
    color: #fff;
}

.menu.navigation_p {
    background: #fff;
}

.accueil_diaporama_template {
    background: #fff;
}

nav#nav-principal {
    .navigation_link_p{
        color: #222;
    }
    .navigation_button_p{

        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: #222;
            .navigation_link_p{
                color:#fff;
            }
        }
    }
    .sous_navigation_link_p{
        color: #fff;
    }
    .sous_navigation_button_p{
        background-color: #222;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: #fff;
            > .sous_navigation_link_p{
                color: #222;
            }
        }
    }
}
@media #{$small-and-down}{
    nav#nav-principal{
        .navigation_button_p{
            background:#fff;
        }
    }
}

nav.secondaire ul li {
    &, & > a {
        color: #fff;
    }
    a.actif {
        color: #FF4747;
    }
}

.message {

    color: #323233;
}

.bx-controls-direction i {
    color: #fff;
}

.slide-text > div {
    //background:#222222;
}

/*
 * Sous-categorie
 */

.btn-toggle-menu, .btn-toggle-menu-none {
    background-color: #748fc9;
}

.produits .addbasket {

    border: 1px solid white;
}

/* Diapo produits page d'accueil */
.produits-accueil .liens-mask {
    background-color: rgba(34, 34, 34, .8);
}

.produits-accueil .produits .addbasket {
    border-color: #222;
    color: #222;
}

.produits-accueil .produits .addbasket:hover, .produits-accueil .produits .details:hover {
    background-color: #222;
    border-color: #222;
    color: #fff;
}

p.nomprod a {
    color: #ff4747;
}

.photo .info-photo {
    background-color: #fff;
}

.produits .addbasket {
    border: 3px solid rgb(255, 255, 255);
}

.photo .info-photo a {
    background-position: 0 -62px;
    color: #555555;
}

.photo .info-photo a:hover {
    background-position: 0 -30px;
    color: #555555;
}

.produit_galerie_border_p {
    border-color: #4060a5;
}

.produits .nomprod {
    background-color: #fff;
    color: #222222;
}

.produits .productImageWrap {
    background-color: #fff;
}

.produits .remise {
    color: #fff;
    background-color: #FF4747;
}

.produits .oldprix {
    color: red;
}

.content-cmonsite .btn-search {
    background: #FF4747;
}

.title_section, h2, .h2 {
    color: #fff;
    border-bottom: 2px solid white;
    a, a:hover {
        color: #fff;
    }
}

.message_lo {
    border: 1px solid white;
    background: white;
}

.message_lo h2.sous-titre,
.message_lo .h2.sous-titre {
    border-bottom-color: #000;
    color: #000;
}

.message_lo .note {
    background-color: #222222;
    border-left-color: #FFFFFF;
    color: #FFFFFF;
}

.web, .web a {
    color: black;
}

.template_livreor_2 {
    .message_lo {
        &, &:hover {
            .IDLO {
                background: #fff;
            }
            &:nth-child(2n+1) .contenuMsgLO, .contenuMsgLO {
                &, .blcFleche::before {
                    background: #fff;
                }
            }
        }
    }
}

.template_livreor_3 .message_lo {
    background: #fff;
}

.footer_p {
    background: none repeat scroll 0 0 rgb(34, 34, 34);
}

.button, .produits .addbasket, .links_p .button {
    border: 2px solid #fff;
    color: white;
}

.produits .zoom {
    color: #fff;
    background-color: #000;
}

.galerie .mask {
    background: #fff;
}

/* Template Panier */

$panier-background-color:#222;
$panier-titre-color:#fff;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
}


.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}